<template>
  <div>
    <div class="google-map" ref="googleMap" />
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <v-bottom-navigation>
        <v-btn id="toggle-heatmap" @click="toggleHeatmap"> Mapa de Calor </v-btn>
        <v-btn id="change-gradient" @click="changeGradient">Cambiar gradiente </v-btn>
        <v-btn id="change-radius" @click="changeRadius">Cambiar radio </v-btn>
        <v-btn id="change-opacity" @click="changeOpacity">Cambiar opacidad </v-btn>
      </v-bottom-navigation>
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>
<script>
  import GoogleMapsApiLoader from 'google-maps-api-loader'

  export default {
    props: {
      mapConfig: Object,
      markers: Array,
      apiKey: String,
    },

    data() {
      return {
        value: '',
        google: null,
        map: null,
        heatmap: null,
      }
    },

    async mounted() {
      const googleMapApi = await GoogleMapsApiLoader({
        apiKey: this.apiKey,
        libraries: ['visualization'],
      })
      this.google = googleMapApi
      this.initializeMap()
    },

    methods: {
      initializeMap() {
        const mapContainer = this.$refs.googleMap
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
        this.heatmap = new this.google.maps.visualization.HeatmapLayer({
          data: this.getPoints(),
          map: this.map,
        })
        this.heatmap.setMap(null)
      },
      toggleHeatmap() {
        this.heatmap.setMap(this.heatmap.getMap() ? null : this.map)
      },
      changeGradient() {
        const gradient = [
          'rgba(0, 255, 255, 0)',
          'rgba(0, 255, 255, 1)',
          'rgba(0, 191, 255, 1)',
          'rgba(0, 127, 255, 1)',
          'rgba(0, 63, 255, 1)',
          'rgba(0, 0, 255, 1)',
          'rgba(0, 0, 223, 1)',
          'rgba(0, 0, 191, 1)',
          'rgba(0, 0, 159, 1)',
          'rgba(0, 0, 127, 1)',
          'rgba(63, 0, 91, 1)',
          'rgba(127, 0, 63, 1)',
          'rgba(191, 0, 31, 1)',
          'rgba(255, 0, 0, 1)',
        ]
        this.heatmap.set('gradient', this.heatmap.get('gradient') ? null : gradient)
      },
      changeRadius() {
        if (this.heatmap.get('radius') === 50) {
          this.heatmap.set('radius', 100)
        } else {
          this.heatmap.set('radius', 50)
        }
      },

      changeOpacity() {
        if (this.heatmap.get('opacity') === 0.3) {
          this.heatmap.set('opacity', 0.6)
        } else {
          this.heatmap.set('opacity', 0.3)
        }
      },
      getPoints() {
        const pointsFormated = []
        this.markers.forEach((point) => {
          pointsFormated.push(
            new this.google.maps.LatLng(point.position.lat, point.position.lng)
          )
        })
        return pointsFormated
      },
    },
  }
</script>
<style scoped>
  .google-map {
    width: 100%;
    min-height: 100%;
  }
</style>
