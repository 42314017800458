<template>
  <v-card :loading="loading ? loading : false">
    <v-skeleton-loader v-if="loading" class="mx-auto my-4" max-width="300" type="card" />
    <div v-else>
      <v-card-title style="justify-content: center"
        ><h2 class="text-center text-h6" style="word-break: break-word">
          {{ item.name ? item.name : '' }}
        </h2>
      </v-card-title>
      <v-card-subtitle class="mt-2 py-0">
        <v-row>
          <v-col cols="12" sm="6" class="d-flex flex-column align-center"
            ><h3 class="mb-2">Datos Operativos</h3>
            <p>
              <strong> Nivel: </strong> {{ item.level.name }}<br />
              <strong>Formato de empresa:</strong> {{ item.type.name }}<br />
              <strong>Empresa principal:</strong>
              {{ item.father ? item.father.name : 'No posee' }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex flex-column align-center"
            ><h3 class="mb-2">Datos de contacto</h3>
            <p>
              <strong>Email:</strong>
              <a class="text-decoration-none" :href="`mailto:${item.email}`">
                {{ item.email }}
              </a>
              <br />
              <strong>Teléfono:</strong>
              <a class="text-decoration-none" :href="`tel:${item.phone}`">
                {{ item.phone }}
              </a>
            </p></v-col
          >
        </v-row>
      </v-card-subtitle>
    </div>

    <!--<v-card-subtitle></v-card-subtitle>-->

    <!-- <v-card-actions>
      <v-btn color="orange lighten-2" text> Opciones </v-btn>

      <v-spacer />

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
<script>
  export default {
    name: 'MapCard',
    data: () => ({
      // show: false,
    }),
    props: {
      item: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        required: false,
      },
    },
  }
</script>
