<script>
  /* eslint-disable vue/require-render-return */
  // import { POINT_MARKER_ICON_CONFIG } from '@/data/GoogleMaps'

  export default {
    props: {
      google: {
        type: Object,
        required: true,
      },
      map: {
        type: Object,
        required: true,
      },
      marker: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        point: {},
      }
    },

    mounted() {
      this.initMap()
    },
    methods: {
      initMap() {
        const { Marker } = this.google.maps
        const infoWindow = new this.google.maps.InfoWindow()

        /* const icon = {
          url: process.env.VUE_APP_WEBSITE,
          // This marker is 20 pixels wide by 32 pixels high.
          size: new this.google.maps.Size(40, 35),
          // The origin for this image is (0, 0).
          origin: new this.google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new this.google.maps.Point(40, 35),
        } */

        // eslint-disable-next-line no-new

        // const image =
        //   'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'
        this.point = new Marker({
          position: this.marker.position,
          marker: this.marker,
          animation: this.google.maps.Animation.DROP,
          label: this.marker.id,
          map: this.map,
          // icon: this.marker.type === 1 ? image : null,
        })
        this.point.addListener('click', (e) => {
          // this.toggleBounce()
          infoWindow.close()
          infoWindow.setContent(this.marker.title)
          // infoWindow.open(this.point.getMap(), this.marker)
          infoWindow.open({
            anchor: this.point,
            map: this.google.maps,
            shouldFocus: false,
          })

          this.placeMarkerAndPanTo(e.latLng)
          // const marker = { lat: e.latLng.lat(), lng: e.latLng.lng() }
          this.$emit('selectedMarker', this.marker)
        })
      },
      placeMarkerAndPanTo(latLng) {
        this.map.panTo(latLng)
      },
      /* toggleBounce() {
        if (this.point.getAnimation() !== null) {
          this.point.setAnimation(null)
        } else {
          this.point.setAnimation(this.google.maps.Animation.BOUNCE)
        }
      }, */
    },

    render() {},
  }
</script>
