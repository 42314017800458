<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <!-- Content -->
      <v-row no-gutters align="center" justify="space-between">
        <smart-breadcrumbs :items="navigationElements" />
        <help :helpId="11" class="help--breadcrumb" />
      </v-row>
      <v-card elevation="4">
        <div class="filters">
          <h2 class="text-center">Geolocalización de Empresas</h2>
          <label>Filtrar por:</label>
          <v-form ref="formCompany" v-model="valid">
            <v-row class="mx-0">
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  dense
                  solo
                  hide-details="auto"
                  label="Búsqueda"
                  v-model="filters.search"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <multiselect
                  track-by="id"
                  label="name"
                  deselect-label=""
                  select-label=""
                  placeholder="País"
                  :custom-label="countryName"
                  v-model="filters.country"
                  :options="countries"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <multiselect
                  track-by="id"
                  label="name"
                  placeholder="Empresa Principal"
                  v-model="filters.company"
                  :options="companiesSearched"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                  :options-limit="300"
                  :limit="3"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="getCompaniesSelector"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <multiselect
                  track-by="id"
                  label="name"
                  placeholder="Nivel"
                  v-model="filters.level"
                  :options="levels"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <multiselect
                  track-by="id"
                  label="name"
                  placeholder="Tipo"
                  v-model="filters.type"
                  :options="types"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <multiselect
                  track-by="id"
                  label="name"
                  deselect-label=""
                  select-label=""
                  placeholder="Activa"
                  v-model="filters.active"
                  :options="activeOptions"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                />
              </v-col>
              <v-col cols="12" lg="1" class="d-flex justify-end justify-lg-start">
                <v-btn
                  color="primary"
                  style="min-width: auto; height: 25px"
                  dark
                  class="pa-1 mx-1"
                  v-if="filterActivated"
                  @click.prevent="
                    filterActivated = false
                    cleanFilters()
                    getCompanies()
                  "
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      style="min-width: auto; height: 25px"
                      dark
                      class="pa-1 mx-1"
                      @click.prevent="
                        filterActivated = true
                        getCompanies()
                      "
                      ><v-icon dark> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <span>Filtrar</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <travel-map
          v-if="companies !== ''"
          :key="mapKey"
          class="travel-map"
          @selectedItem="handleSelectedCompany($event)"
          style="height: 600px"
          ref="googleMap"
          :markers.sync="markers"
          :paths.sync="paths"
        />
        <v-dialog v-model="dialog" :width="dialogWidth">
          <map-card :item.sync="company" />
        </v-dialog>
      </v-card>
    </v-container>
  </div>
</template>
<script>
  import TravelMap from '@/components/Maps/TravelMap'
  import MapCard from '@/components/Cards/MapCard'
  export default {
    name: 'Map',
    components: { TravelMap, MapCard },
    data() {
      return {
        dialog: false,
        filterActivated: false,
        valid: true,
        mapKey: 1,
        navigationElements: [
          {
            text: 'Estructura Corporativa',
            disabled: false,
            query: null,
            params: null,
            name: 'business/corporate-structure/AdminPanel',
            href: '/business/corporate-structure/admin-panel',
          },
          {
            text: 'Geolocalización de Empresas',
            disabled: true,
            href: 'administrative/corporate-structure/map',
          },
        ],
        isLoading: false,
        company: {},
        companies: '',
        companiesSearched: [],
        totalItems: 0,
        itemsPerPage: 10,
        page: 1,
        pages: 1,
        countries: [],
        types: [],
        levels: [],
        filters: {
          search: '',
          active: null,
          country: null,
          company: null,
          type: null,
          level: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        paths: [
          { lat: 25.754873569667627, lng: -80.29263208256326 },
          { lat: 36.72222254212065, lng: -4.42185187724881 },
          { lat: -34.625232889658164, lng: -58.40793288308955 },
          { lat: -12.009354964068622, lng: -77.12789301535891 },
          { lat: -0.2874896799552792, lng: -78.32987508309856 },
          { lat: -34.875816191604414, lng: -56.18702516893915 },
        ],
      }
    },
    created() {
      this.getCompanies()
      this.getCountries()
      this.getCompaniesSelector()
      this.getLevels()
      this.getTypes()
    },
    computed: {
      markers() {
        if (this.companies.length > 0) {
          const points = []
          this.companies.forEach((company) => {
            points.push({
              id: String(company.id),
              title: company.name,
              type: company.type.id,
              position: {
                lat: parseFloat(company.latitude),
                lng: parseFloat(company.longitude),
              },
            })
          })
          return points
        } else {
          return []
        }
      },
      dialogWidth() {
        if (this.$vuetify.breakpoint.xsOnly) {
          return '95%'
        } else if (this.$vuetify.breakpoint.smOnly) {
          return '80%'
        } else {
          return '50%'
        }
      },
    },
    methods: {
      limitText(count) {
        return `and ${count} other companies`
      },
      async getCompaniesSelector(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        const withString = '&with[]=type&with[]=level'
        this.$axios
          .get('companies?order=name&by=asc' + searchStr + withString)
          .then((response) => {
            this.companiesSearched = response.data.data
            this.isLoading = false
          })
      },
      async getLevels() {
        this.$axios.get('levels?limit=5').then((response) => {
          this.levels = this.levels.concat(response.data)
        })
      },
      async getCountries() {
        this.$axios.get('countries?limit=500').then((response) => {
          this.countries = this.countries.concat(response.data)
        })
      },
      async getTypes() {
        this.$axios.get('types?limit=20').then((response) => {
          this.types = this.types.concat(response.data)
        })
      },

      async getCompanies() {
        this.loading = true
        let queryParams = ''
        if (this.filterActivated) {
          let searchString = ''
          let countryString = ''
          let companyString = ''
          let levelString = ''
          let typeString = ''
          let activeString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.country && this.filters.country.id) {
            countryString = '&country_id=' + this.filters.country.id
          }
          if (this.filters.company && this.filters.company.id) {
            companyString = '&company_id=' + this.filters.company.id
          }
          if (this.filters.level && this.filters.level.id) {
            levelString = '&level_id=' + this.filters.level.id
          }
          if (this.filters.type && this.filters.type.id) {
            typeString = '&type_id=' + this.filters.type.id
          }
          if (this.filters.active && this.filters.active.id === 1) {
            activeString = '&active=1'
          } else if (this.filters.active && this.filters.active.id === 0) {
            activeString = '&active=0'
          }
          queryParams =
            searchString +
            countryString +
            companyString +
            levelString +
            typeString +
            activeString
        }
        this.$axios
          .get('companies?with[]=type&limit=200&page=' + this.page + queryParams)
          .then((response) => {
            this.companies = response.data
            if (this.companies.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loading = false
            this.mapKey++
          })
      },

      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },
      handleSelectedCompany(marker) {
        this.company = this.companies.find(
          (company) => company.id === parseInt(marker.id)
        )
        this.dialog = true
      },
      cleanFilters() {
        this.filters = {
          search: '',
          active: null,
          country: null,
        }
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
