<template>
  <GoogleMapLoader :mapConfig="mapConfig" :markers="markers" :apiKey="apiKey">
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
        @selectedMarker="$emit('selectedItem', $event)"
      />
      <!-- <google-map-polygon
        v-if="paths && paths.length > 0"
        :paths="paths"
        :google="google"
        :map="map"
      /> -->
      <!--<GoogleMapLine
        v-for="line in lines"
        :key="line.id"
        :path.sync="line.path"
        :google="google"
        :map="map"
      />-->
    </template>
  </GoogleMapLoader>
</template>

<script>
  import GoogleMapLoader from '@/components/Maps/GoogleMapLoader'
  import GoogleMapMarker from '@/components/Maps/GoogleMapMaker'
  // import GoogleMapLine from '@/components/Maps/GoogleMapLine'

  import { mapSettings } from '@/data/GoogleMaps'
  // import GoogleMapPolygon from './GoogleMapPolygon.vue'

  export default {
    components: {
      GoogleMapLoader,
      GoogleMapMarker,
      // GoogleMapPolygon,
      // GoogleMapLine,
    },

    props: ['markers', 'paths'],

    data() {
      return {
        apiKey: process.env.VUE_APP_GMAP_APIKEY,

        /* lines: [
            {
              id: '1',
              path: [
                { lat: 10.4962552, lng: -66.8462447 },
                { lat: 10.4970472, lng: -66.8439653 },
              ],
            },
            {
              id: '2',
              path: [
                { lat: 10.4970472, lng: -66.8439653 },
                { lat: 10.4966959, lng: -66.8356848 },
              ],
            },
          ], */
      }
    },

    computed: {
      mapConfig() {
        return {
          ...mapSettings,
          center: this.mapCenter ? this.mapCenter : { lat: 0, lng: 0 },
        }
      },

      mapCenter() {
        if (this.markers.length > 0) {
          return this.markers[0].position
        } else {
          return null
        }
      },
    },
  }
</script>
